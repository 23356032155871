import React from "react";
import GaugeComponent from "react-gauge-component";

const BiasComponent = ({ payload }) => {
    let text = "test";
    return (
        <div>
            <GaugeComponent
                id="gauge-component4"
                type="semicircle"
                labels={{ tickLabels: { hideMinMax: true }, valueLabel: { formatTextValue: (x) => payload.biasText } }}
                arc={{
                    gradient: true,
                    width: 0.15,
                    padding: 0,
                    subArcs: [
                        {
                            limit: 30,
                            color: "#5B9BD5",
                            showTick: false,
                        },
                        { color: "#FF6F61" },
                    ],
                }}
                value={payload.bias}
                pointer={{ type: "arrow", elastic: true }}
            />
        </div>
    );
};

export default BiasComponent;
