import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function HomePage() {
    const [query, setQuery] = useState("");
    const navigate = useNavigate();

    const handleSearch = (e) => {
        e.preventDefault();
        navigate(`/search?q=${encodeURIComponent(query)}`);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}>
            <h1 style={{ fontSize: "4rem", marginBottom: "2rem" }}>Mock Search</h1>
            <form onSubmit={handleSearch} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    style={{ padding: "0.5rem", fontSize: "1rem", width: "300px", marginBottom: "1rem" }}
                    placeholder="Enter your search query"
                />
                <button
                    type="submit"
                    style={{
                        padding: "0.5rem 1rem",
                        fontSize: "1rem",
                        backgroundColor: "#4285F4",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                    }}
                >
                    Search
                </button>
            </form>
        </div>
    );
}

export default HomePage;
