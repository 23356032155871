import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const RedirectComponent = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { id, botVariant = "b", portalVariant } = useParams();

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
            navigate(`/news/${id}/${botVariant}`, { replace: true, state: { portalVariant: portalVariant } });
        }, 2);

        return () => clearTimeout(timer);
    }, []);

    return <div>{loading ? <div>Loading...</div> : null}</div>;
};

export default RedirectComponent;
