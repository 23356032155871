import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import SearchResultsPage from "./components/SearchResultsPage";
import ResultPage from "./components/ResultPage";
import ArticlePage from "./components/NewsPage";
import PortalPage from "./components/PortalPage";
import { useTracking } from "react-tracking";
import RedirectComponent from "./components/RedirectComponent";

function App() {
    const [sequence, setSequence] = React.useState([]);
    const { Track, trackEvent } = useTracking({ page: "NewsPage" });
    return (
        <Track>
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/search" element={<SearchResultsPage />} />
                    <Route path="/result/:id" element={<ResultPage />} />
                    <Route path="/news/:id/:botVariant?" element={<ArticlePage />} />
                    <Route path="/go/:id/:botVariant/:portalVariant" element={<RedirectComponent />} />
                    <Route path="/portal/:id/:botVariant?" element={<PortalPage sequence={sequence} setSequence={setSequence} />} />
                </Routes>
            </Router>
        </Track>
    );
}

export default App;
