import { pagesContent } from "../pages/merged";
export const getAlternatives = (acticleId) => {
    const readingArticle = pagesContent[acticleId];
    const readingLeaning = readingArticle.bias;
    const alternativeLeaning = readingLeaning == "Right" ? "Left" : "Right";
    const alternativeArticles = pagesContent.allIds.filter((id) => pagesContent[id].bias == alternativeLeaning);
    const shuffledIndices = Array.from({ length: alternativeArticles.length }, (_, i) => i).sort(() => Math.random() - 0.5);
    return {
        readingLeaning: readingLeaning.toLowerCase(),
        alternativeLeaning: alternativeLeaning.toLowerCase(),
        alternativeArticles: [alternativeArticles[0], alternativeArticles[1]],
    };
};
