import React from "react";
import { getAlternatives } from "../diet/alternatives";
import { useNavigate, useParams } from "react-router-dom";
import { pagesContent } from "../pages/merged";

const SuggestionComponent = ({ payload, portalVariant }) => {
    const navigate = useNavigate();
    const articleId = payload.articleId;
    const suggestedArticleIds = getAlternatives(articleId);
    return (
        <div>
            {suggestedArticleIds.alternativeArticles.map((id) => (
                <a
                    className="text-white px-4 py-2 my-4 rounded"
                    onClick={(e) => navigate(`/go/${id}/b/${portalVariant}`)}
                    style={{ background: "#5CCC9D", display: "block", fontSize: "0.8rem" }}
                >
                    {pagesContent[id].title}
                </a>
            ))}
        </div>
    );
};

export default SuggestionComponent;
