import { useContext } from "react";
import { biasContext, siteContext } from "./components/ResultPage";
import React from "react";
import { getBias } from "./diet/bias";
import { createCustomMessage } from "react-chatbot-kit";
import { getAlternatives } from "./diet/alternatives";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
    const bias = useContext(biasContext);
    const site = useContext(siteContext);
    const provideBiasInformation = () => {
        const biasValue = getBias(window.dataLayer) * 100;
        const biasText = biasValue < 40 ? "Left" : biasValue > 60 ? "Right" : "Neutral";
        const message1 = createChatBotMessage(`Awesome! Here’s a graph showing your political leanings based on what you've been reading.`);
        const message2 = createCustomMessage("bias", "biasGauge", { payload: { bias: biasValue, biasText: biasText } });

        updateChatbotState(message1);
        updateChatbotState(message2);
    };
    const askSuggestionYesNo = () => {
        const { readingLeaning, alternativeLeaning } = getAlternatives(window.dataLayer[window.dataLayer.length - 1].id);
        const question = createChatBotMessage(
            `This article you've just read has a ${readingLeaning}-leaning perspective. Would you like to explore alternative news from a ${alternativeLeaning}-leaning source?`
        );
        const message = createCustomMessage("askSuggestionsYesNo", "askSuggestionsYesNo");
        updateChatbotState(question);
        updateChatbotState(message);
    };
    const provideSuggestions = () => {
        const { alternativeLeaning } = getAlternatives(window.dataLayer[window.dataLayer.length - 1].id);
        const intro = createChatBotMessage(`Here are two ${alternativeLeaning}-leaning articles for you to consider:`);
        const message = createCustomMessage("suggestion", "suggestion", { payload: { articleId: window.dataLayer[window.dataLayer.length - 1].id } });
        const message2 = createChatBotMessage("I can take you directly to either article. Which one would you like to read?");
        updateChatbotState(intro);
        updateChatbotState(message);
        updateChatbotState(message2);
    };
    const handleBiasNo = () => {
        const message = createChatBotMessage(
            "It’s alright, I’m on standby anytime. Feel free to ask me whenever you’re interested to know your media diet!"
        );
        updateChatbotState(message);
    };
    const handleSuggestionNo = () => {
        const message = createChatBotMessage(
            "That’s perfectly fine. If you change your mind, feel free to ask for alternative perspectives anytime!"
        );
        updateChatbotState(message);
    };

    const handleDefault = () => {
        const message = createChatBotMessage("I'm sorry, I don't understand. Can you select one of the options above?");
        updateChatbotState(message);
    };

    const updateChatbotState = (message) => {
        setState((prevState) => ({
            ...prevState,
            messages: [...prevState.messages, message],
        }));
    };
    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        provideBiasInformation,
                        provideSuggestions,
                        handleDefault,
                        handleBiasNo,
                        handleSuggestionNo,
                        askSuggestionYesNo,
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;
