import { createChatBotMessage, createCustomMessage } from "react-chatbot-kit";
import BiasComponent from "./components/BiasComponent";
import YesNoBiasComponent from "./components/YesNoBiasComponent";
import SuggestionComponent from "./components/SuggestionComponent";
import YesNoSuggestionComponent from "./components/YesNoSuggestionComponent";

const botName = "Media Diet Bot";

export const configBiasBot = {
    initialMessages: [
        createChatBotMessage(
            `Hey! I have been keeping a record of your reading pattern, including all the articles you have read. Would you like to know the political leaning of your media diet?`
        ),
        createCustomMessage("askBiasYesNo", "askBiasYesNo"),
    ],
    botName: botName,
    customStyles: {
        botMessageBox: {
            backgroundColor: "#376B7E",
        },
        chatButton: {
            backgroundColor: "#5ccc9d",
        },
    },
    customMessages: {
        biasGauge: (props) => <BiasComponent {...props} />,
        suggestion: (props) => <SuggestionComponent {...props} />,
        askBiasYesNo: (props) => <YesNoBiasComponent {...props} />,
        askSuggestionsYesNo: (props) => <YesNoSuggestionComponent {...props} />,
    },
};

export const configSuggestionBot = (readingLeaning, alternativeLeaning, portalVariant) => {
    return {
        initialMessages: [
            createChatBotMessage(
                `This article you've just read has a ${readingLeaning}-leaning perspective. Would you like to explore alternative news from a ${alternativeLeaning}-leaning source?`
            ),
            createCustomMessage("askSuggestionsYesNo", "askSuggestionsYesNo"),
        ],
        botName: botName,
        customStyles: {
            botMessageBox: {
                backgroundColor: "#376B7E",
            },
            chatButton: {
                backgroundColor: "#5ccc9d",
            },
        },
        customMessages: {
            biasGauge: (props) => <BiasComponent {...props} />,
            suggestion: (props) => <SuggestionComponent {...props} portalVariant={portalVariant} />,
            askBiasYesNo: (props) => <YesNoBiasComponent {...props} />,
            askSuggestionsYesNo: (props) => <YesNoSuggestionComponent {...props} />,
        },
    };
};

export default configBiasBot;
