import React from "react";
import { pagesContent } from "../pages/merged";
import { useNavigate, useParams } from "react-router-dom";

// Dummy articles array
const combos = {
    1: ["electiondemo1", "electiondemo2", "electiondemo3", "electiondemo4", "electiondemo5", "electiondemo6", "electionrep3", "electionrep2"],
    2: ["electionrep1", "electionrep2", "electionrep3", "electionrep4", "electionrep5", "electionrep6", "electiondemo2", "electiondemo4"],
};

const categories = ["Politics", "Climate Change", "Immigration", "Abortion", "Healthcare"];

const PortalPage = ({ sequence, setSequence }) => {
    const { id, botVariant = "a" } = useParams();
    const navigate = useNavigate();
    const [navIndex, setNavIndex] = React.useState(0);
    console.log(sequence);
    if (sequence.length === 0) {
        setSequence(Array.from({ length: 8 }, (_, i) => i).sort(() => Math.random() - 0.5));
    }

    const articles_raw = combos[id].map((article) => {
        var articleObj = pagesContent[article];
        articleObj.key = article;
        return articleObj;
    });
    const articles = sequence.map((index) => articles_raw[index]);
    const handleClick = (article_id) => {
        navigate(`/news/${article_id}/${botVariant}`, { state: { portalVariant: id } });
    };
    return (
        <div className="bg-gray-50 min-h-screen">
            <header className="shadow-sm py-4 px-6" style={{ background: "#adf0d1" }}>
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <img src="/image/logo.svg" alt="Icon" className="w-8 h-8 mr-2" />
                        <h1 className="text-2xl font-bold w-80 poppins-bold" style={{ color: "#0d120f" }}>
                            The News Desk
                        </h1>
                    </div>
                    {/* Search Bar */}
                    <div className="flex items-center">
                        <input
                            type="text"
                            placeholder="Search for topics, locations & sources"
                            className="border rounded-lg py-2 px-4 w-96 text-gray-700 font-sans"
                            style={{ borderColor: "#D8F4E8" }}
                        />
                    </div>
                    <div className="w-80"></div>
                </div>

                {/* Navigation Menu */}
                <nav className="flex justify-center space-x-6 mt-4">
                    {categories.map((category, index) => (
                        <span
                            key={index}
                            style={{ color: "#466365" }}
                            className={
                                navIndex == index
                                    ? "text-black font-semibold hover:underline cursor-pointer text-lg font-sans"
                                    : "text-gray-700 hover:underline cursor-pointer text-lg font-sans"
                            }
                        >
                            {category}
                        </span>
                    ))}
                </nav>
            </header>
            {/* Main Content */}
            <main className="p-6 max-w-7xl mx-auto">
                <h2 className="text-lg font-semibold text-gray-500 mb-4 font-sans">Recommended based on your interests</h2>

                {/* 2x4 Grid of News Articles */}
                <div className="grid max-w-7xl grid-cols-1 md:grid-cols-2 gap-0 rounded-lg">
                    {articles.map((article, index) => (
                        <div
                            key={index}
                            className="p-4 shadow flex items-start bg-white cursor-pointer hover:bg-gray-100"
                            onClick={(e) => handleClick(article.key)}
                        >
                            <div className="flex-grow">
                                {/* Article Title */}
                                <h2 className="text-xl font-bold font-serif text-gray-900 mt-1 line-clamp-2">{article.title}</h2>
                                {/* Date and Author */}
                                <p className="text-base text-gray-800 mt-2 font-serif ">{article.abstract}</p>
                            </div>
                            {/* Dummy Image Preview */}
                            <div className="ml-4">
                                <div className="w-28 h-28 bg-gray-200 rounded-md">
                                    <img src={article.image} alt="Article Preview" className="w-full h-full object-cover rounded-md" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </main>
        </div>
    );
};

export default PortalPage;
