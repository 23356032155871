import React from "react";

const YesNoBiasComponent = ({ actions }) => {
    console.log(actions);
    return (
        <div className="flex flex-col items-center">
            <div className="flex flex-col space-y-4 mt-4">
                <button
                    className="text-white px-4 py-2 rounded"
                    style={{ background: "#5CCC9D" }}
                    onClick={(e) => {
                        actions.provideBiasInformation();
                    }}
                >
                    Yes, please.
                </button>
                <button className="text-white px-4 py-2 rounded" onClick={(e) => actions.handleBiasNo()} style={{ background: "#5CCC9D" }}>
                    No, thank you.
                </button>
            </div>
        </div>
    );
};

export default YesNoBiasComponent;
