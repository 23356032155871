import React from "react";

const MessageParser = ({ children, actions }) => {
    const parse = (message) => {
        const lowerCaseMessage = message.toLowerCase();

        if (false) {
            actions.provideBiasInformation();
        } else {
            actions.handleDefault();
        }
    };
    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    parse: parse,
                    actions,
                });
            })}
        </div>
    );
};

export default MessageParser;
