import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { allPages } from "../pages/pageContents";

function shuffle(arr) {
    var j, x, index;
    for (index = arr.length - 1; index > 0; index--) {
        j = Math.floor(Math.random() * (index + 1));
        x = arr[index];
        arr[index] = arr[j];
        arr[j] = x;
    }
    return arr;
}

function SearchResultsPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [inputQuery, setInputQuery] = useState("");
    const [displayedQuery, setDisplayedQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const q = searchParams.get("q");
        if (q) {
            setInputQuery(q);
            setDisplayedQuery(q);
            performSearch(q);
        }
    }, [location.search]);

    const performSearch = (searchQuery) => {
        var maxKeywords = 0;
        const results = Object.entries(allPages).map(([key, page]) => {
            const lowerCaseContent = page.bodyText.toLowerCase();
            const keywords = searchQuery.toLowerCase().split(" ");
            var foundKeywords = 0;
            for (const keywd of keywords) {
                if (lowerCaseContent.includes(keywd) || page.keywords.includes(keywd)) {
                    foundKeywords++;
                }
            }
            if (foundKeywords > maxKeywords) {
                maxKeywords = foundKeywords;
            }
            return { key: key, page: page, foundKeywords: foundKeywords };
        });

        var filteredResults = [];
        for (var i = maxKeywords; i > 0; i--) {
            filteredResults = [...filteredResults, ...shuffle(results.filter((res) => res.foundKeywords == i))];
        }
        console.log(filteredResults);

        const processedResults = filteredResults.map((res) => {
            console.log(res);
            const lines = res.page.bodyText.split("\n");
            const title = res.page.title.replace(/^#\s*/, "");
            const snippet = (lines.slice(0).join(" ").substring(0, 250) + "...").replace(/#+/, "");
            return { id: res.key, title, snippet };
        });

        setSearchResults(processedResults);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        navigate(`/search?q=${encodeURIComponent(inputQuery)}`);
        setDisplayedQuery(inputQuery);
        performSearch(inputQuery);
    };

    return (
        <div style={{ padding: "2rem" }}>
            <div style={{ marginBottom: "2rem" }}>
                <form onSubmit={handleSearch} style={{ display: "flex", alignItems: "center" }}>
                    <input
                        type="text"
                        value={inputQuery}
                        onChange={(e) => setInputQuery(e.target.value)}
                        style={{ padding: "0.5rem", fontSize: "1rem", width: "300px", marginRight: "1rem" }}
                        placeholder="Enter your search query"
                    />
                    <button
                        type="submit"
                        style={{
                            padding: "0.5rem 1rem",
                            fontSize: "1rem",
                            backgroundColor: "#4285F4",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                        }}
                    >
                        Search
                    </button>
                </form>
            </div>

            <h4>Search Results for: {displayedQuery}</h4>
            {searchResults.length === 0 ? (
                <p>No results found.</p>
            ) : (
                <ul style={{ listStyleType: "none", padding: 0 }}>
                    {searchResults.map((result) => (
                        <li key={result.id} style={{ marginBottom: "3rem" }}>
                            <Link to={`/result/${result.id}`} style={{ color: "#1a0dab", textDecoration: "none" }}>
                                <h3>{result.title}</h3>
                            </Link>
                            <p>{result.snippet}</p>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default SearchResultsPage;
