export const page1md = {
    title: "AI-powered brain surgery becomes a reality in Hong Kong after launch from state-run research centre",
    keywords: ["application", "success", "experiment", "case", "minimal invasive", "successful", "trail"],
    site: "BBC",
    bias: "Balanced",
    bodyText: `#### The Centre for Artificial Intelligence and Robotics, under the Chinese Academy of Sciences, completed successful trials of a robot that treats brain tumours

A research center in Hong Kong, part of the prestigious Chinese Academy of Sciences (CAS), is preparing to introduce an advanced robotics system for brain surgery. Despite challenges like a lack of skilled professionals and AI chips, the Center for Artificial Intelligence and Robotics (CAIR), established in 2019, has made significant progress.

The robot, named MicroNeuro, is equipped with a flexible endoscope and uses AI and precise control to perform accurate surgeries on brain tissue—a challenging task due to the brain's delicate nature.

Liu Hongbin, the center's executive director, revealed in an interview that the MicroNeuro robot successfully completed three trials on cadavers. This robot specializes in performing deep brain surgery with minimal invasiveness, a departure from traditional methods involving rigid tools and large scalp incisions that can harm healthy brain tissue.

Liu stressed the importance of technology in brain surgery, considering it a risky procedure. Surgeons are eager to utilize AI and technology to make these surgeries less invasive.

CAIR's robotic system provides surgeons with a delicate, flexible tool and an AI-guided navigation system, incorporating augmented reality to reduce brain tissue damage by at least 50%. Collaborative trials with the Prince of Wales Hospital in Hong Kong have shown the robot's ability to perform minimally invasive brain surgeries for treating brain tumors.

The center is currently refining the robotic system to meet regulatory standards and aims to start clinical trials next year pending approval from the city's Hospital Authority.`,
};

export const page2md = {
    title: "Artificial Intelligence in Surgery: Why and How.",
    keywords: ["why", "reason", "mechanism", "explanation", "application", "case", "example", "automation"],
    site: "CNN",
    bias: "Left",
    bodyText: `The use of AI systems combined with robotics has been implemented for many surgical procedures, aiding and guiding the surgeon in real-time by improving decision-making and providing better precision and safety to the surgeon during the remotely guided procedure. There are already dozens of companies that are devoted to making AI surgeons more widely applied during surgeries. This article aims to introduce the existing types of AI surgeons and the AI technology that prepared them in the operation room. 

### Machine Learning and deep learning as the base for AI algorithm 

AI encompasses various subtypes showing great promise in clinical applications. Machine Learning (ML) uses algorithms to predict and learn from data autonomously. ML stands out by learning behaviors without explicit programming, categorized as supervised, unsupervised, and reinforcement learning. In supervised learning, algorithms predict outcomes using labeled data; unsupervised learning uncovers patterns in unlabeled data; and reinforcement learning operates through trial and error, akin to operant conditioning. These methods are all evaluated in healthcare.

Deep learning, a subset of ML, employs neural networks to analyze large datasets and solve complex problems. Within deep learning are deep neural networks with multiple hidden layers, mimicking the complexity of biological neural networks. Deep learning excels in identifying intricate patterns, particularly in medical imaging diagnostics.

### Enhancing Surgery with NLP and Computer Vision

In surgical contexts, natural language processing (NLP) and computer vision play crucial roles, revolutionizing how surgeries are performed and documented. NLP, a branch of AI that focuses on the interaction between computers and human language, converts spoken language into structured text. This capability aids in analyzing clinical narratives and electronic health records (EHRs), enabling the extraction of valuable insights from unstructured data. By automating documentation, NLP can significantly reduce the administrative burden on surgeons, allowing them to focus more on patient care. For instance, during surgeries, NLP can transcribe spoken notes and convert them into structured records, ensuring accurate and comprehensive documentation without interrupting the surgical workflow.

Computer vision, another AI technology, allows algorithms to analyze images and videos, extracting patterns and relevant information from surgical archives. This technology can interpret visual data from preoperative imaging, intraoperative video feeds, and postoperative assessments. By identifying patterns and anomalies, computer vision can assist in diagnosing conditions, planning surgical procedures, and providing real-time guidance during surgeries. For example, computer vision algorithms can analyze laparoscopic video feeds to identify critical anatomical structures, flag potential complications, and suggest optimal surgical paths, thereby enhancing surgical precision and safety.

Moreover, the integration of NLP and computer vision can lead to advanced applications such as automated surgical video analysis. This can include annotating surgical procedures, identifying key steps, and generating summaries that can be used for educational purposes or quality control. By leveraging large datasets of surgical videos and associated clinical data, these AI technologies can continuously improve their accuracy and effectiveness, leading to better patient outcomes and more efficient surgical practices.

There are generally two types of AI surgeons that help human surgeons in doing their job easier and even better! 

### AI-driven robotic assistant. 

Surgical robots have been utilized for over 20 years, though improved hardware and artificial intelligence (AI) applications now allow surgical robots to assist in increasingly complex procedures. Unlike traditional methods, where surgeons rely solely on their manual dexterity, these robotic systems integrate advanced technologies, including AI and sophisticated mechanical components. The primary objective is to enhance precision, improve outcomes, and enable minimally invasive procedures. Robotic arms allow surgeons to operate through much smaller incisions compared to traditional open surgery and even laparoscopic procedures. Surgical robots are designed to augment the skills of human surgeons, providing them with greater control, flexibility, and access to intricate anatomical structures. These systems often consist of robotic arms equipped with specialized surgical instruments, controlled by surgeons through a console, that can maneuver within the body with a high level of precision. The systems translate the surgeon’s hand movements into scaled, fluid motions, eliminating natural human tremors that can compromise accuracy. The incorporation of AI provides real-time data analysis, helps avoid obstacles during surgery, warns of potential complications, and allows surgeons to "feel" the tissues and structures being manipulated, even during a minimally invasive procedure.

Now, based on consistent supervised learning and unsupervised learning, AI robotic operations can learn from the images and records of human surgeons, giving AIs a similar and even better ability to operate. The utilization of removing the surgeon’s hands is called autonomous robotic surgery. With the ability to learn and make it to use, AI has the power to catalyze the experience of human surgeons and practice with precision and efficiency.  

### The decision-facilitating AI- intraoperative guidance. 
 
Database-backed learning supports doctors in making medical decisions. AI technologies, particularly those assessing imaging data, have the potential to revolutionize early and precise diagnosis for symptomatic patients. In ophthalmology, various deep learning methods have proven effective in diagnosing retinal diseases using optical coherence tomography or retinal fundus photographs.

Automated speech recognition and Natural Language Processing (NLP) automate the recording, extraction, and summarization of information from physician-patient consultations. This technology can alleviate the administrative burden on clinicians, a major contributor to burnout, enabling more patient-focused care amidst increasing outpatient demands. An ML algorithm has successfully charted symptoms discussed during patient-clinician interactions, particularly well-defined symptoms. 

Computer vision plays a pivotal role by providing real-time guidance to human surgeons. AI algorithms, primarily based on deep learning, can analyze vast amounts of surgical video data to offer insights and recommendations. Three-dimensional anatomical reconstruction powered by AI aids in preoperative planning and intraoperative navigation for complex procedures. For instance, a system that creates patient-specific arterial anatomy maps has reduced radiation exposure and enhanced the safety of intricate surgical processes.

AI represents a gateway to a future healthcare era, with the global surgical community already embracing its potential. It is essential for everyone to acknowledge that AI, along with its underlying technologies, has fundamentally altered our lives.
`,
};

export const page3md = {
    title: "The DOWNSIDE of AI in healthcare system",
    keywords: [
        "cons",
        "disadvantage",
        "problem",
        "risk",
        "security",
        "limitation",
        "bedside",
        "ban",
        "law",
        "responsibility",
        "challenge",
        "money",
        "finance",
        "cost",
    ],
    site: "FOX News",
    bias: "Right",
    bodyText: `Artificial Intelligence (AI) has brought many changes to various fields, especially healthcare. AI has great potential such as improving diagnostics, providing personalized treatments, and making healthcare operations more efficient. However, there are also significant challenges and drawbacks that must be carefully considered to ensure AI is used safely and fairly in healthcare.

### Bias and Fairness Concerns
AI systems are only as good as the data they are trained on. If the training data is biased or not representative of everyone, AI predictions and recommendations will also be biased. For example, if an AI system is trained only with data from White patients, it might not make accurate diagnoses for Asian patients. In healthcare, these biases can lead to unequal treatment and misdiagnoses, especially for minority and underserved communities. To ensure fairness, AI systems need diverse and comprehensive datasets, along with continuous monitoring and adjustment of the algorithms.

### Regulatory and Legal Challenges
The rapid development of AI in healthcare has moved faster than the creation of regulatory frameworks. There are no standard guidelines for using AI in healthcare, making it complex to navigate the regulatory and legal challenges. This uncertainty can slow down the adoption of AI technologies and raise concerns about compliance and liability. It's important to make sure AI systems follow existing healthcare regulations and create new policies to address AI-specific issues for safe and effective use.

### Reliability and Accountability Concerns
Reliability and accountability are crucial for AI in healthcare. Determining who is responsible when errors occur can be difficult. For example, if an AI system makes a misdiagnosis, it can be hard to decide whether to blame the AI developers, healthcare providers, or the data used for training. Establishing clear accountability frameworks is essential to address these concerns and ensure responsible use of AI systems.

### High Costs of Development and Implementation
Developing and integrating AI technologies in healthcare is expensive. Creating sophisticated AI systems requires significant investments in research, data acquisition, and computational resources. Additionally, incorporating these systems into existing healthcare infrastructures demands substantial financial commitments. These high costs can be a barrier for many healthcare organizations, especially those with limited budgets.

Governments face similar financial challenges when introducing AI into public health systems. Public health systems often operate on tight budgets and must carefully allocate resources. The initial costs of developing and deploying AI technologies, including hardware, software, and skilled personnel, can be prohibitive. To overcome these financial barriers, governments may need to provide subsidies, grants, or partnerships with private sector organizations. Additionally, demonstrating long-term cost savings and efficiency gains from AI implementation is crucial to justify the initial investment.

### Cybersecurity Risks
AI systems in healthcare are vulnerable to cybersecurity threats like ransomware, malware, and data breaches. These threats can disrupt healthcare operations, compromise patient data, and erode trust in AI technologies. Ransomware attacks can lock healthcare providers out of critical patient data, leading to treatment delays and endangering patient lives. Malware infections can tamper with patient records, resulting in incorrect diagnoses or treatments. Data breaches can expose sensitive patient information, potentially leading to identity theft and fraud. Furthermore, integrating AI into healthcare systems expands the attack surface, providing more vulnerabilities for cybercriminals to exploit.

These challenges highlight the need for careful consideration and management of the drawbacks associated with AI surgeons to ensure safe and effective use in healthcare.
`,
};

export const page4md = {
    title: "The Rise of AI in the Operating Room: Revolutionizing Surgery",
    keywords: ["application", "success", "example", "case", "Hong Kong", "use", "industry", "future", "plan", "development"],
    site: "BBC",
    bias: "Balanced",
    bodyText: `Artificial intelligence (AI) is transforming various fields, and medicine is no exception. Imagine a world where machines assist in intricate surgeries, providing unparalleled precision and efficiency. This isn’t a scene from a sci-fi movie but a reality unfolding in hospitals today.

AI in medicine is about creating algorithms that empower machines to solve complex problems, recognize visual elements, and make predictions based on vast data. In the surgical arena, AI can analyze extensive data from patient records, radiological scans, and surgical videos. This capability allows it to detect anomalies, classify conditions, and predict outcomes with remarkable accuracy.

One of the standout strengths of AI in surgery is its ability to enhance decision-making. By integrating data from various sources—like the latest surgical guidelines and research insights—AI aids surgeons before, during, and after procedures. It can review patient charts, suggest tests or medications, and bring crucial information to the operating table.

Academic medical centers, with their robust IT infrastructures, are leading the way in implementing AI-based tools. These tools excel in pattern recognition, image classification, and object detection through "computer vision." For example, AI helps radiologists by prioritizing images, analyzing stacks of chest X-rays in minutes—a task that would take hours for a human.

AI's prowess extends to identifying high-risk cases that might be overlooked. A study highlighted that AI reduced the error rate in recognizing cancer-positive lymph nodes from 3.4% to 0.5%, showcasing its potential to improve diagnostic accuracy significantly.

Dr. Kilic's team at the Medical University of South Carolina (MUSC) is pioneering AI algorithms to identify high-risk transplant patients and evaluate donor organs. Their goal is to enhance transplant decisions and optimize the allocation of scarce resources—donor organs. By analyzing millions of historic surgeries and patient characteristics, AI can stratify the risks and benefits of specific surgeries, tailoring them to individual patients.

Moreover, AI and machine learning are tapping into extensive patient databases to develop robust predictive algorithms. These advancements aim to provide surgeons with a more comprehensive understanding of a patient's risks and benefits, potentially revolutionizing how we approach complex surgeries.

In summary, AI is not just a futuristic concept but an emerging reality in the surgical field. Its ability to process vast amounts of data, integrate diverse expertise, and provide real-time insights is set to revolutionize surgery, making procedures safer and more efficient.

For more information on the advancements and applications of AI in surgery, check out the latest studies and expert insights from leading medical institutions.
`,
};

export const page5md = {
    title: "Four Ways AI is Revolutionizing the Operating Room",
    keywords: ["application", "success", "example", "case", "Hong Kong", "use", "industry", "future", "plan", "development"],
    bodyText: `### 1. Large sets of data learning and processing
Imagine a world where your surgeon not only has decades of experience but also the ability to learn from thousands of surgeries in mere seconds. That’s the potential of AI in the medical field. AI-based systems can absorb a vast amount of data at lightning speed. While human surgeons spend years perfecting their skills through countless surgeries, AI can process recordings of thousands of procedures almost instantaneously, remembering each one with precise accuracy. This means that AI can help educate surgeons at all stages of their careers, from seasoned professionals to those in developing regions, by providing a centralized source of surgical knowledge.

### 2. Updated with latest trends
But AI’s role doesn’t stop at education. It also offers surgeons a new perspective by recognizing trends and introducing new methodologies. By analyzing data from surgeries worldwide, AI can identify patterns and suggest the best techniques, potentially standardizing practices across the globe. This can lead to better outcomes for patients, as surgeons everywhere adopt the most effective methods identified by AI.

### 3.Reducing cognitive stress and instability
One of the significant benefits of AI in surgery is its ability to relieve the cognitive and physical stress of surgeons. AI systems can highlight tools, monitor operations, and send alerts, guiding procedures to ensure a smoother process. This can save critical operating time and reduce the cognitive load on surgeons, allowing them to perform more procedures with greater efficiency and better results. Additionally, AI can suggest ergonomically smarter solutions to alleviate the physical strain surgeons endure, helping to prolong their careers.

### 4.Revolutionizing surgical care
AI isn’t just about improving the skills and experiences of surgeons; it’s also about expanding access to surgical care. Currently, two-thirds of the global population lacks proper access to surgical treatment. AI, paired with advanced robotics, has the potential to bridge this gap. It can ensure that more physicians have access to the best learning opportunities, supporting them in performing surgeries regardless of their location or available resources. This means that surgeons who might only perform one type of procedure today could broaden their impact by addressing a wider variety of sub-specialties, thanks to AI-based surgical robotics.
`,
};

export const page6md = {
    title: "The Future of AI Surgeons in Hong Kong: Transforming Neurosurgery",
    keywords: ["application", "success", "example", "case", "Hong Kong", "use", "industry", "future", "plan", "development"],
    bodyText: `The Centre for Artificial Intelligence and Robotics (CAIR) in Hong Kong, a branch of the Chinese Academy of Sciences (CAS), has introduced an advanced AI tool designed to assist in complex brain surgeries. This development marks a significant leap forward in medical technology, addressing the healthcare industry's challenge of insufficient specialised databases for such intricate procedures.

The newly launched CARES Copilot 1.0 AI model is tailored to enhance neurosurgical practices by offering more efficient clinical diagnoses and informed medical judgments. As CAIR's executive director Liu Hongbin noted, the system is designed to help neurosurgeons by synthesising vast amounts of information quickly and accurately.

CARES Copilot 1.0 has already undergone extensive testing in various hospitals across Hong Kong and mainland China. It has been integrated into the workflow of medical professionals to assist in both surgical planning and post-surgery management. Liu highlighted the tool's capability to provide comprehensive references, thereby aiding doctors in making well-informed decisions.

The AI system's deployment aligns with Hong Kong's future five-year medical development and education plan. Negotiations are underway to incorporate the CARES Copilot system into the curriculum of medical universities in Hong Kong, enhancing the training of future doctors.

During a live demonstration at the Hong Kong Science Park, Danny Chan Tat-ming, head of the neurosurgery division at the Chinese University of Hong Kong's Department of Surgery, showcased the tool's impressive functionality. The CARES Copilot 1.0 model can generate key information from numerous academic papers, including citations, within seconds, achieving an accuracy rate of up to 95%.

The AI model, based on Meta Platforms’ Llama 2 large language model (LLM), has been trained on an extensive array of multimodality databases. These include text, images, MRI, CT scans, and ultrasound imaging, specifically tailored for the medical field. According to Liu, the robustness of the model is directly linked to the size and diversity of its training datasets. To enhance its capabilities, the research team has fed CARES Copilot 1.0 with thousands of medical textbooks, academic papers, and international neurosurgical guidelines.

Clinical decision-making in neurosurgery often requires synthesising information from multiple sources, such as CT scans, MRI, and patients’ physiological tests. By integrating these diverse data points, the CARES Copilot system aims to make more accurate and effective medical decisions for AI surgeon.

Founded in 2019, CAIR serves as a bridge connecting Hong Kong's international talent and top-tier academic research with the industrial needs of mainland China. This initiative not only represents a technological milestone but also underscores Hong Kong's strategic role in advancing global medical practices through innovative AI solutions. 

With the introduction of CARES Copilot 1.0, Hong Kong is poised to become a leader in AI-assisted neurosurgery, setting new standards for precision and efficiency in the medical field. As AI continues to evolve, its application in healthcare promises to revolutionise the way complex surgical procedures are performed, ultimately improving patient outcomes worldwide.
`,
};

export const page7md = {
    title: "The Future of AI Surgeons in Hong Kong: Enhancing Surgical Precision and Reducing Wait Times",
    keywords: [
        "application",
        "success",
        "example",
        "case",
        "Hong Kong",
        "use",
        "industry",
        "future",
        "plan",
        "development",
        "benefit",
        "pros",
        "strength",
        "support",
        "need",
        "urge",
    ],
    bodyText: `Hong Kong is on the brink of a transformative shift in its healthcare system with the potential introduction of AI-assisted surgery in public hospitals. This move aims to expedite non-emergency surgeries and significantly reduce the long waiting times for specialist outpatient services.

AI has already proven its value in diagnostic radiology by enhancing the sensitivity and specificity of radiological examinations. The integration of AI algorithms in intraoperative image processing and analysis has substantially improved the quality and interpretability of surgical images. Advanced machine learning models, particularly deep learning, excel in noise reduction, contrast enhancement, and feature extraction, providing surgeons with clearer visualizations of anatomical structures and pathological entities. These advancements enable more informed decisions and precise manipulations, ultimately leading to better surgical outcomes.

Libby Lee Ha-yun, Under Secretary for Health, disclosed in an interview that the Hospital Authority is currently reviewing the use of AI to analyze CT scans and improve the predictive accuracy of diagnosing conditions such as malignancies in pulmonary nodules. These successes in radiology are being translated into the surgical domain, heralding a new era of enhanced intraoperative procedures. AI's role in image processing, real-time segmentation, and minimally invasive and robotic surgeries is becoming increasingly significant, backed by relevant case studies and clinical outcomes in Hong Kong's public hospitals.

To address the overcrowding in acute hospitals, the Hospital Authority has formulated long-term plans and measures to incorporate AI-assisted surgeries. By allocating resources to develop and subscribe to AI surgical tools, Hong Kong aims to alleviate the pressure on its public healthcare system and enhance both the quality and quantity of healthcare services.

Lee believes AI will initially benefit minimally invasive and robotic surgeries. The combination of AI and intraoperative imaging enhances surgical performance. AI's machine vision allows robotic systems to interpret images in real time, ensuring accurate navigation, tissue identification, and execution of surgical tasks. This reduces surgical trauma and speeds up recovery, showcasing AI's transformative potential in modern surgery.

Recent years have seen numerous use cases in general surgery, with laparoscopic procedures being the most prominent. These include laparoscopic cholecystectomy, colectomy, and sleeve gastrectomy, with laparoscopic cholecystectomy being the most represented.

As Hong Kong continues to explore and implement AI-assisted surgery, the potential for improved patient outcomes and reduced wait times becomes increasingly promising. The future of surgery in Hong Kong looks set to be revolutionized by the integration of AI, offering new hope and enhanced healthcare services for its population.
`,
};

export const page8md = {
    title: "AI Surgeons: The Future of Hong Kong's Public Medical System",
    keywords: [
        "application",
        "success",
        "example",
        "case",
        "Hong Kong",
        "use",
        "industry",
        "future",
        "plan",
        "development",
        "benefit",
        "pros",
        "strength",
        "support",
        "need",
        "urge",
    ],
    bodyText: `Artificial intelligence (AI) is set to revolutionize the way surgeries are performed in Hong Kong's public hospitals. By integrating AI into surgical planning and patient selection, especially in preoperative imaging, doctors can make early diagnostic evaluations and better plan surgeries. This includes using reinforcement learning methods to detect and remove tumor tissue while minimizing damage to healthy tissues during neurosurgery.

One of the key innovations is the use of AI and virtual reality-based segmentation algorithms for preoperative planning, which significantly improves surgical techniques. Precise preoperative clinical diagnoses are essential for making informed decisions and planning effective treatments. For instance, the RadioLOGIC AI algorithm extracts data from radiological reports to enhance radiological diagnoses. Advances in large language models (LLMs) and their interaction with electronic data systems may allow for earlier illness detection and treatment, preventing disease progression.

Diagnosis is a well-developed aspect of surgical AI, with early clinical applications demonstrating the generalizability and accuracy of AI models. Specific advancements in tasks such as tumor staging can greatly improve surgical planning. Preoperative planning, where surgeons plan procedures based on medical records and imaging, is crucial for surgical success. Common imaging modalities like X-rays, CT scans, ultrasounds, and MRIs are used to classify anatomy, detect issues, segment images, and register data.

By incorporating these AI technologies, Hong Kong's public medical system can expect enhanced efficiency and effectiveness in surgical procedures. AI can streamline the preoperative planning process, ensuring that surgeons are better prepared and more precise in their operations. This not only improves patient outcomes but also helps manage the workload in public hospitals, reducing waiting times for surgeries and improving overall healthcare services.
`,
};

export const page9md = {
    title: "Ethical, Legal, and Financial Considerations of Artificial Intelligence in Surgery",
    keywords: [
        "cons",
        "disadvantage",
        "problem",
        "risk",
        "security",
        "limitation",
        "bedside",
        "ban",
        "law",
        "responsibility",
        "challenge",
        "money",
        "finance",
        "cost",
    ],
    bodyText: `
The integration of artificial intelligence (AI) into surgical practices brings new possibilities and challenges. This article explores the ethical, legal, and financial aspects of using AI in surgery in a way that is understandable to a general audience, including high school students.

### Ethical Considerations

As AI tools become more common in surgery, new ethical issues arise related to patient safety, medical responsibility, and decision-making. While machine learning algorithms can provide useful predictions and improve surgical outcomes, they also raise questions about who is responsible if something goes wrong—the doctors or the developers of the AI.

Moreover, using large amounts of patient data to train AI models brings up important questions about privacy, data security, and whether patients have given proper consent. It's crucial to protect patient interests and ensure that data practices are transparent as AI becomes more integrated into medical procedures. This means we must develop strong ethical guidelines that prioritize patient care and fairness.

### Legal and Regulatory Challenges

The legal landscape for AI in surgery needs careful attention to issues like liability, certification, and regulation. With the rise of autonomous robotic surgery, we need clear rules about the roles and responsibilities of surgeons, engineers, and AI developers, especially if errors or malfunctions occur. Creating strong certification processes and federal regulations for AI systems is essential to keep patients safe and maintain high ethical standards in surgery.

As AI technologies rapidly evolve, legal frameworks must be flexible to adapt to new advancements and address emerging ethical issues. Balancing innovation with regulation is key to building trust in AI applications in surgery and protecting patient welfare. Legal systems also need to consider international standards since AI in surgery crosses national borders and requires global cooperation.

### Financial Evaluations

While AI promises improved surgical outcomes and efficiency, the financial aspect of AI in surgery is complex. Comprehensive cost-benefit analyses and funding considerations are essential for informed decision-making. Studies comparing the costs of robotic surgeries with traditional procedures show variability in cost-effectiveness, highlighting the need for tailored financial assessments in different surgical contexts.

Funding for AI research in healthcare often comes from individual researchers and institutional grants, requiring a strategic approach to finance large-scale AI integration within healthcare systems. Additionally, stakeholders must consider the long-term financial sustainability of AI technologies, including maintenance costs, training expenses, and the potential for obsolescence as new innovations emerge.

Navigating the financial uncertainties of AI-driven surgery requires a thorough understanding of the long-term value, potential cost savings, and investment strategies to ensure sustainable adoption and equitable access to AI technologies. Health economists and policymakers must collaborate to develop models that accurately reflect the economic impact of AI in surgery and guide resource allocation decisions.

### Conclusion

The integration of artificial intelligence in surgery represents a major shift in healthcare delivery, involving a mix of ethical, legal, and financial considerations that require careful thought and proactive management. By fostering a culture of ethical integrity, regulatory compliance, and financial prudence, stakeholders can harness the transformative potential of AI to enhance surgical practices, improve patient outcomes, and advance healthcare innovation. Achieving the full benefits of AI in surgery will require ongoing dialogue, interdisciplinary collaboration, and a commitment to addressing the various challenges that lie ahead.
`,
};

export const page10md = {
    title: "AI for Surgeons: Current Realities, Future Possibilities",
    keywords: ["application", "success", "example", "case", "Hong Kong", "use", "industry", "future", "plan", "development"],
    bodyText: `In 2012, a patient arrived at Stanford University Medical Center with a large wound on his foot. The man had been walking on it for a month before coming in for care.

Elsie Gyang Ross, MD, quickly recognized the patient had a circulatory condition known as peripheral artery disease (PAD), in which vessels narrow and restrict or block blood flow to the limbs. The condition affects an estimated 8 million to 12 million Americans but often goes undiagnosed. If left untreated, PAD can increase the risk for stroke and myocardial infarction or, in this case, lead to a wound that doesn’t heal.

Dr. Ross’ patient had wet gangrene of the foot and, even after antibiotics, debridement and revascularization, he ultimately required a below-knee amputation.

"But a lot can be done to treat patients before we need to consider surgery, such as amputation or lower extremity angiography," Dr. Ross, an assistant professor of surgery and medicine at the Stanford University School of Medicine, in California, told General Surgery News.

In particular, Dr. Ross said, what if clinicians could use artificial intelligence to diagnose patients sooner or predict who would develop PAD so patients can begin treatment earlier and avoid surgery?

In 2014, Dr. Ross, along with her colleagues at Stanford University’s Center for Biomedical Informatics Research, began evaluating machine learning algorithms to identify patients at risk for PAD. Machine learning algorithms—mathematical tools that help make sense of data—learn from experience. In other words, the more data fed into the algorithm, the more accurate the diagnosis or prediction becomes.

A 2016 analysis described the process of constructing machine learning models using data from 1,755 patients who had undergone elective coronary angiography but whose PAD status was unknown. Dr. Ross and his team found that the machine learning algorithms could recognize PAD and predict mortality better than standard linear regression models (J Vasc Surg 2016;64[5]:1515-1522.e3). A 2019 study that included 7,686 patients with PAD and incorporated almost 1,000 variables from electronic health records reported that the machine learning models could accurately forecast which PAD patients would develop major cardiac and cerebrovascular events (Circulat Cardiovasc Qual Outcomes 2019;12).

The goal of these models and others, Dr. Ross explained, is not for machine learning or its operationalized counterpart, AI, to take over care or make decisions for surgeons. "AI is there to make us better," she said. "It should run quietly in the background, catching our blind spots and helping us make more informed decisions about patient care."

Developing and validating AI and machine learning models takes time and an enormous amount of data before they can be integrated into clinical practice. However, interest in AI technology has ballooned in the past few years, with approvals by the FDA increasing from just two at the end of 2017 to more than 80 by September 2020.

At the 2020 American College of Surgeons Clinical Congress, Dr. Ross, along with Genevieve B. Melton-Meaux, MD, PhD, FACS, and Rachael A. Callcut, MD, FACS, explored the latest advances in surgical AI, highlighting tools that can improve diagnosis, decision making and outcomes in surgery (abstract PS424).

In addition to diagnosing and prioritizing more urgent cases, AI-based tools can help predict outcomes or improve surgeons’ performance in the OR.

During her talk, Dr. Ross discussed an imaging platform that guides surgeons during endovascular aneurysm repair. The intraoperative image-based, 3D, fusion CT automates conversion of preoperative scans into 3D models and, in the OR, overlays these images onto the patient’s live fluoroscopy to highlight where the renal arteries are and where a guide wire should go.

"Preliminary use of this technology has decreased important metrics for vascular surgeons, such as radiation exposure as well as fluoroscopy and procedure time," Dr. Ross said, pointing to a 2018 abstract published in the Journal of Vascular Surgery (2018;67[6]:E61).

As for outcomes, Dr. Melton-Meaux highlighted a risk prediction model called POTTER—Predictive OpTimization Trees in Emergency surgery Risk—that calculates a patient’s risk for 30-day mortality and 18 postoperative complications. Surgeons can use the POTTER app to determine a range of risk factors by answering a series of questions that change depending on the previous response. An analysis published in Annals of Surgery in 2020 showed that in 29,366 patients undergoing emergency surgery, the model accurately predicted mortality in all patients over 65 years of age, and also septic shock, respiratory failure requiring mechanical ventilation, and acute renal failure (Ann Surg 2020 Dec 23. [Epub ahead of print]).

"It is clear that health care is digitizing rapidly and that AI will permeate it increasingly and in profound ways," Dr. Melton-Meaux, a professor of surgery and Core Faculty of the Institute for Health Informatics at the University of Minnesota, in Minneapolis, said during her ACS talk.

Despite these trends, Dr. Callcut cautioned that "we’re still in the hype cycle of AI—a lot of excitement but the applications have not been scaled yet."

Dr. Ross agreed, highlighting other limitations and challenges of AI. "AI is also not going to do robotic surgeries for us. That vision of AI is far off, especially given the technology we have now."

The advantages of AI center on assisting physicians’ management of their patients by helping to make difficult, time-sensitive diagnoses, prevent postoperative complications, and increase the accuracy and efficiency of surgery.

"We don’t want to do AI for AI’s sake, but if we can make a dent in improving surgical outcomes and decreasing physician burden, investing in AI-based technology is worthwhile," Dr. Ross said.
`,
};

export const page11md = {
    title: "Artificial Intelligence in Ophthalmic Surgery: Current Applications and Expectations",
    keywords: ["application", "success", "example", "case", "Hong Kong", "use", "industry", "future", "plan", "development"],

    bodyText: `Though most people might find AI surgeons unfamiliar, in fact, this technology has been used in ophthalmic surgery, achieving robust recognition and classification.  

Ophthalmic surgery is widely recognized as a complex and delicate form of microsurgery, requiring meticulous precision within the confined intraocular environment. Consequently, there is a paramount emphasis on the imperative need for precise control. It is widely acknowledged that AI demonstrates exceptional proficiency in the acquisition and simulation of the extensive digital data generated during surgical procedures. This technological advancement serves to overcome the inherent limitations faced by physicians during such procedures, ultimately enhancing both efficiency and accuracy. Furthermore, the integration of corneal tomographic imaging with AI plays a crucial role in assessing the appropriateness of patients for surgical interventions, thereby facilitating informed surgical decision-making. Moreover, the field of ophthalmology is currently experiencing significant progress in the domain of robot-assisted technology, with a particular focus on advancements in retinal surgery. Robotic platforms have exhibited promising outcomes, as evidenced by encouraging human studies. Lastly, AI has the potential to predict postoperative vision and adjust the surgical plan, thereby enabling personalized treatment and enhancing surgical precision.
`,
};

export const allPages = {
    0: page1md,
    1: page2md,
    2: page3md,
    3: page4md,
    4: page5md,
    5: page6md,
    6: page7md,
    7: page8md,
    8: page9md,
    9: page10md,
    10: page11md,
};
