import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { pagesContent } from "../pages/merged";
import { useTracking } from "react-tracking";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import { configBiasBot, configSuggestionBot } from "../ChatbotConfig";
import MessageParser from "../MessageParser";
import ActionProvider from "../ActionProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot, faClose } from "@fortawesome/free-solid-svg-icons";
import { getAlternatives } from "../diet/alternatives";
import { useNavigate, useLocation } from "react-router-dom";

const categories = ["Politics", "Climate Change", "Immigration", "Abortion", "Healthcare"];

const ArticlePage = () => {
    const { id, botVariant = "a" } = useParams();
    const article = pagesContent[id];
    const [showBot, setShowBot] = useState(false);
    const { Track, trackEvent } = useTracking({ page: "NewsPage" });
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        trackEvent({ action: "ReadArticle", id: id });
    }, []);
    const { readingLeaning, alternativeLeaning } = getAlternatives(id);
    return (
        <div>
            <div className="bg-white text-black font-serif mx-auto">
                {/* Header */}
                <header className="mb-6 w-full">
                    <h1
                        className="text-2xl font-bold text-center pt-2 cursor-pointer"
                        onClick={(e) => {
                            navigate(`/portal/${location.state.portalVariant}/${botVariant}`);
                        }}
                    >
                        The News Desk
                    </h1>
                    <nav className="flex justify-center space-x-6 text-sm py-5 mt-4 border-b border-t border-gray-300">
                        {categories.map((category, index) => (
                            <span key={index} className="text-black font-sans font-semibold hover:underline cursor-pointer">
                                {category}
                            </span>
                        ))}
                    </nav>
                </header>
            </div>
            <div className="bg-white text-black font-serif mx-auto max-w-3xl p-6">
                {/* Article Content */}
                <article className="space-y-4">
                    <h1 className="text-3xl font-bold leading-tight">{article.title}</h1>
                    <p className="text-sm font-sans">Published: {article.date}</p>

                    {/* Dummy Share Buttons */}
                    <div className="flex space-x-4">
                        <button className="px-4 py-2 font-sans text-black rounded-full border border-gray-300 hover:bg-gray-300">Share</button>
                        <button className="px-4 py-2 font-sans text-black rounded-full border border-gray-300 hover:bg-gray-300">Bookmark</button>
                    </div>

                    {/* Body Text (Markdown content) */}
                    <div className="prose prose-lg justify-normal">
                        <img src={article.image} alt={article.title} className="mb-6 w-full" />
                        <ReactMarkdown className={"markdown"}>{article.bodyText}</ReactMarkdown>
                    </div>
                    <p className="text-sm text-gray-600 mt-2 font-sans">
                        By {Array.isArray(article.author) ? article.author.join(", ") : article.author}
                    </p>
                </article>
            </div>
            <div
                style={
                    showBot
                        ? {
                              position: "fixed",
                              bottom: "116px",
                              right: "24px",
                              boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                              borderRadius: "10px",
                              overflow: "hidden",
                          }
                        : {
                              position: "fixed",
                              bottom: "116px",
                              right: "24px",
                              boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                              borderRadius: "10px",
                              overflow: "hidden",
                              display: "none",
                          }
                }
            >
                {botVariant == "a" ? (
                    <Chatbot config={configBiasBot} messageParser={MessageParser} actionProvider={ActionProvider} />
                ) : (
                    <Chatbot
                        config={configSuggestionBot(readingLeaning, alternativeLeaning, location.state.portalVariant)}
                        messageParser={MessageParser}
                        actionProvider={ActionProvider}
                    />
                )}
            </div>
            <Fab
                style={{ bottom: 0, right: 0 }}
                icon={showBot ? <FontAwesomeIcon icon={faClose}></FontAwesomeIcon> : <FontAwesomeIcon icon={faRobot}></FontAwesomeIcon>}
                onClick={(e) => setShowBot(!showBot)}
            />
        </div>
    );
};

export default ArticlePage;
