import React from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { allPages } from "../pages/pageContents";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import configBiasBot from "../ChatbotConfig";
import MessageParser from "../MessageParser";
import ActionProvider from "../ActionProvider";

export const biasContext = React.createContext("Neutral");
export const siteContext = React.createContext("Unknown");

function ResultPage() {
    const { id } = useParams();
    const pageContent = allPages[parseInt(id)];

    if (!pageContent) {
        return <div>Result not found</div>;
    }

    const title = pageContent.title; // Extract title
    const bias = pageContent.bias; // Extract bias
    const site = pageContent.site; //

    return (
        <div style={{ padding: "2rem" }}>
            <h1>{title}</h1>
            <ReactMarkdown>{pageContent.bodyText}</ReactMarkdown>
            <div
                style={{
                    position: "fixed",
                    bottom: "20px",
                    right: "20px",
                    boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                    borderRadius: "10px",
                    overflow: "hidden",
                }}
            >
                <biasContext.Provider value={bias}>
                    <siteContext.Provider value={site}>
                        <Chatbot config={configBiasBot} messageParser={MessageParser} actionProvider={ActionProvider} />
                    </siteContext.Provider>
                </biasContext.Provider>
            </div>
        </div>
    );
}

export default ResultPage;
