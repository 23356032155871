import { pagesContent } from "../pages/merged";
export const getBias = (trackingObjs) => {
    const biases = [];
    for (const trackingObj of trackingObjs) {
        const pageContent = pagesContent[trackingObj.id];
        biases.push(pageContent.bias == "Right" ? 1 : pageContent.bias == "Left" ? 0 : 0.5);
    }
    const averageBias = biases.reduce((acc, bias) => acc + bias, 0) / biases.length;
    return averageBias;
};
